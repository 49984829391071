async function createPersistedSearches(store) {
  const module = await import('vuex-persist')
  const VuexPersistence = module.default
  new VuexPersistence({
    key: 'searches',
    modules: ['searches'],
  }).plugin(store)
}

export default ({ store }) => {
  createPersistedSearches(store)
}
