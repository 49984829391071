import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export function createRouter(ssrContext, createDefaultRouter, routerOptions) {
  const options = routerOptions || createDefaultRouter(ssrContext).options
  const hostname = ssrContext ? ssrContext.req.headers.host : location.host
  return new Router({
    ...options,
    routes: handleRoutes(options.routes, hostname),
  })
}

function handleRoutes(defaultRoutes, hostname) {
  if (hostname.includes('vendo')) return vendoRoutes(defaultRoutes)
  return primaryRoutes(defaultRoutes)
}

function primaryRoutes(defaultRoutes) {
  return defaultRoutes.filter((r) => !r.name.includes('subdomain-vendo'))
}

function vendoRoutes(defaultRoutes) {
  const routes = defaultRoutes
    .filter((r) => r.name.includes('subdomain-vendo'))
    .map((r) => {
      r.path = r.path.replace('/subdomain-vendo', '')
      return r
    })
  return routes
}
